import { get } from 'svelte/store';
import { type Detail } from './types';
import { _ } from 'svelte-i18n';

export const DETAIL_DEFAULTS = {
  atmosphere: { name: 'kit.details.atmosphere', icon: 'chat' },
  food: { name: 'kit.details.food', icon: 'food' },
  service: { name: 'kit.details.service', icon: 'service' },
  clean_glass: { name: 'kit.details.clean_glass', icon: 'clean-glass' },
  clean_house: { name: 'kit.details.clean_house', icon: 'clean-house' },
  clean: { name: 'kit.details.clean', icon: 'clean' },
  cleaner: { name: 'kit.details.cleaner', icon: 'cleaner' },
  delivery: { name: 'kit.details.delivery', icon: 'fast-courier' },
  fast: { name: 'kit.details.fast', icon: 'timer' },
  hookah: { name: 'kit.details.hookah', icon: 'hookah' },
  hot_food: { name: 'kit.details.hot_food', icon: 'hot-serve' },
  packaging: { name: 'kit.details.packaging', icon: 'food-bag' },
  professional: { name: 'kit.details.professional', icon: 'professional' },
  quality: { name: 'kit.details.quality', icon: 'diamond' },
  recommend: { name: 'kit.details.recommend', icon: 'recommend' },
  team: { name: 'kit.details.team', icon: 'team' },
};

export const DEFAULT_DETAILS: Detail[] = [
  {
    key: 'atmosphere',
  },
  {
    key: 'food',
  },
  {
    key: 'service',
  },
];

export function formatDetailName(detail: Detail) {
  let name = get(_)(detail.name) || '';
  let [firstWord, ...rest] = name?.split(' ') || [];
  return `${firstWord}\n ${rest.join(' ')}`;
}
