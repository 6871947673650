export function postReview(data) {
  return fetch('/api/reviews', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}

export function patchReview(data) {
  return fetch('/api/reviews', {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}

export function uploadReviewImages(reviewId: string, formData: FormData) {
  return fetch(`/api/reviews/uploads/${reviewId}`, {
    method: 'POST',
    body: formData,
  });
}
