<script lang="ts">
  import type { SvelteComponent } from 'svelte';

  import User from './User.svelte';

  import { kitComponents } from 'ui/kit/kit';
  import { recalculateKitComponents } from 'ui/kit/services/render.service';
  import store from 'ui/kit/store/kit.store';
  import type { Employee, EmployeeTips, Kit } from 'ui/kit/types';

  export let employees: Employee[];
  export let selectedEmployees: EmployeeTips[];
  export let kit: Kit;

  const renderedComponentList: { [key: string]: SvelteComponent } = {};

  $: recalculateKitComponents(kit.components, $store);

  function selectUser(user: Employee) {
    selectedEmployees = [...selectedEmployees, { ...user, tips: 0 }];
  }
</script>

{#if selectedEmployees[0]}
  {#each kit.components as component}
    {#if component._isShown}
      <div class="mb-6">
        <svelte:component
          this={kitComponents[component.component]}
          store={$store}
          key={component.key}
          {...component.props}
          bind:this={renderedComponentList[component.key]}
        />
      </div>
    {/if}
  {/each}

  <User user={selectedEmployees[0]} />
{:else}
  {#each employees as user}
    <User {user} on:click={() => selectUser(user)} />
  {/each}
{/if}
