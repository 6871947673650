<script lang="ts">
  import { onMount } from 'svelte';

  import { sendEvent } from '../../analytics/analytics.service';
  import store from '../../store/kit.store';

  import { DETAIL_DEFAULTS } from './constants';
  import type { Detail } from './types';

  import EtRating from 'ui/elements/et-rating/et-rating.svelte';

  export let key = 'details';
  export let details: Detail[] = [{ key: 'atmosphere' }, { key: 'food' }, { key: 'service' }];

  let selectedDetail: Detail = null;

  function getName(detail: Detail) {
    return detail.name || DETAIL_DEFAULTS[detail.key].name;
  }

  function getIcon(detail: Detail) {
    const icon = detail.icon || DETAIL_DEFAULTS[detail.key].icon;
    return `/assets/illustrations/${icon}.svg#illustration-${icon}`;
  }

  function selectDetail(detail: Detail) {
    selectedDetail = detail;
  }

  function changeRate(e: CustomEvent) {
    const rate = e.detail as number;
    selectedDetail.rate = rate;
    updateStore();
  }

  function updateStore() {
    $store[key] = details.reduce<string[]>((acc, detail) => {
      if (typeof detail.rate === 'number') {
        acc.push(`${detail.key}: ${detail.rate}`);
      }
      return acc;
    }, []);

    sendEvent('tips_option', {
      tips: {
        details: $store[key],
      },
    });
  }

  onMount(() => {
    selectedDetail = details[0];
  });
</script>

<div class="details">
  {#each details as detail}
    <button
      type="button"
      class="detail"
      class:is-selected={selectedDetail?.key === detail.key}
      on:click={() => selectDetail(detail)}
    >
      <svg class="u-icon">
        <use xlink:href={getIcon(detail)} />
      </svg>

      <div>{getName(detail)}</div>
    </button>
  {/each}
</div>

{#if selectedDetail}
  <div class="mt-4">
    <h2 class="t-h3 text-center mb-2">{getName(selectedDetail)}</h2>
    <EtRating bind:value={selectedDetail.rate} on:change={changeRate} />
  </div>
{/if}

<style>
  .details {
    display: grid;
    gap: 12px;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }

  .detail {
    border: 1px solid var(--color-primary);
    border-radius: 10px;
    padding: 10px 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 11px;

    --icon-size: 60px;
  }

  .detail.is-selected {
    background-color: var(--color-primary);
    color: #fff;
  }
</style>
