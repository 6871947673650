<script lang="ts">
  import IconPause from 'ui/icons/IconPause.svg';
  import { _ } from 'svelte-i18n';
</script>

<div>
  <main class="main page-menu" id="main-container">
    <div class="banner">
      <div class="mb-7">
        <IconPause />
      </div>

      <h1 class="mb-2">{$_('suspended.title')}</h1>
      <p>{$_('suspended.sub-title')}</p>
    </div>
  </main>
</div>

<style>
  .main {
    padding: 0;
    margin-top: calc(-1 * var(--main-border-radius));
    background-color: var(--base-bg);
    border-radius: var(--main-border-radius) var(--main-border-radius) 0 0;
  }

  .banner {
    background: #f7f7f7;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px 20px;
    padding: 40px 20px;
    text-align: center;
  }

  .banner h1 {
    font-size: 18px;
    font-weight: 500;
  }

  .banner p {
    font-size: 12px;
  }

  .banner :global(svg) {
    color: #a9c9ff;
    font-size: 63px;
  }
</style>
