<script lang="ts">
  import { _ } from 'svelte-i18n';

  export let value: string;
  export let required = false;
  export let placeholder = $_('kit.email');

  let isDirty = false;

  $: isInvalid = isDirty && required && !value;

  function onInput(e) {
    isDirty = true;
    value = (e.target.value as string).trim();
  }
</script>

<div class="sr-only">{placeholder}</div>
<input
  class="form-control"
  class:is-error={isInvalid}
  {placeholder}
  type="email"
  name="email"
  value={value || ''}
  {required}
  on:input={onInput}
/>

{#if isInvalid}
  <p class="feedback is-invalid">{$_('kit.required-field')}</p>
{/if}
