import Amount from './elements/Amount/Amount.svelte';
import AmountSuggest from './elements/AmountSuggest/AmountSuggest.svelte';
import Choices from './elements/Choices/Choices.svelte';
import Comment from './elements/Comment/Comment.svelte';
import Details from './elements/Details/Details.svelte';
import Email from './elements/Email/Email.svelte';
import ExternalOrderId from './elements/ExternalOrderId/ExternalOrderId.svelte';
import FaceExpression from './elements/FaceExpression/FaceExpression.svelte';
import FaceSelect3 from './elements/FaceSelect3/FaceSelect3.svelte';
import MultiChoices from './elements/MultiChoices/MultiChoices.svelte';
import Name from './elements/Name/Name.svelte';
import Phone from './elements/Phone/Phone.svelte';
import RateDetails from './elements/RateDetails/RateDetails.svelte';
import Rating from './elements/Rating/Rating.svelte';
import Tips from './elements/Tips/Tips.svelte';
import TripadvisorReview from './elements/TripadvisorReview/TripadvisorReview.svelte';
import UnitName from './elements/UnitName/UnitName.svelte';
import UploadImage from './elements/UploadImage/UploadImage.svelte';
import type { KitStore } from './store/kit.store';

export const kitComponents = {
  Amount,
  AmountSuggest,
  Rating,
  Comment,
  Details,
  Phone,
  Email,
  Name,
  UploadImage,
  FaceExpression,
  Choices,
  TripadvisorReview,
  Tips,
  ExternalOrderId,
  UnitName,
  MultiChoices,
  RateDetails,
  FaceSelect3,
  // CarouselQuestions,
};

export const defaultKitKeys = new Map<string, keyof KitStore>([
  ['Amount', 'amount'],
  ['AmountSuggest', 'amount'],
  ['Rating', 'rating'],
  ['Comment', 'comment'],
  ['Details', 'details'],
  ['Phone', 'phone'],
  ['Email', 'email'],
  ['Name', 'name'],
  ['UploadImage', 'images'],
  ['FaceExpression', 'rating'],
  ['Choices', 'choices'],
  ['TripadvisorReview', 'tripadvisorReview'],
  ['ExternalOrderId', 'externalOrderId'],
  ['RateDetails', 'details'],
  ['FaceSelect3', 'face'],
]);
