<script lang="ts">
  import { onDestroy } from 'svelte';
  import { _ } from 'svelte-i18n';

  import store from '../../store/kit.store';

  export let isFormDirty: boolean;
  export let key = 'email';
  export let required = false;
  export let title = '';
  export let placeholder = $_('kit.enter-email');

  let isDirty = false;

  $: isInvalid = (isDirty || isFormDirty) && required && !$store[key];

  function onInput(e) {
    isDirty = true;
    $store[key] = (e.target.value as string).trim();
  }

  onDestroy(() => {
    $store[key] = null;
  });
</script>

{#if title}
  <p class="text-center mb-3">{title}</p>
{/if}

<input
  class="form-control"
  class:is-error={isInvalid}
  {placeholder}
  type="email"
  name={key}
  value={$store[key] || ''}
  {required}
  on:input={onInput}
/>

{#if isInvalid}
  <p class="feedback is-invalid">{$_('kit.required-field')}</p>
{/if}
