<script lang="ts">
  import { sendEvent } from '../../analytics/analytics.service';
  import store from '../../store/kit.store';

  import { DEFAULT_DETAILS, DETAIL_DEFAULTS, formatDetailName } from './constants';
  import type { AVAILABLE_DETAILS, Detail } from './types';

  export let key = 'details';
  export let details: Detail[] = [...DEFAULT_DETAILS];

  $: detailsToChoose = details
    .map((detail: Detail) => {
      const defaults = DETAIL_DEFAULTS[detail.key] || {};

      return {
        ...defaults,
        ...detail,
      };
    })
    .filter((detail) => detail);

  function selectDetail(detail: Detail) {
    if (!$store[key] || !Array.isArray($store[key])) {
      $store[key] = [];
    }

    const storeDetails = $store[key] as AVAILABLE_DETAILS[];

    if (storeDetails.includes(detail.key)) {
      $store[key] = storeDetails.filter((i) => i !== detail.key);
    } else {
      $store[key] = storeDetails.concat([detail.key]);
    }

    sendEvent('tips_option', {
      tips: {
        details: $store[key],
      },
    });
  }
</script>

<div class="details">
  {#each detailsToChoose as detail}
    <button
      type="button"
      class="detail"
      class:is-selected={$store[key]?.includes(detail.key)}
      on:click={() => selectDetail(detail)}
    >
      <svg class="u-icon">
        <use xlink:href={`/assets/illustrations/${detail.icon}.svg#illustration-${detail.icon}`} />
      </svg>

      <div class="detail-name">{formatDetailName(detail)}</div>
    </button>
  {/each}
</div>

<style>
  .details {
    display: flex;
    gap: 12px;
    overflow-x: auto;
  }

  .detail {
    border: 1px solid var(--color-primary);
    border-radius: 10px;
    padding: 10px 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 11px;
    flex-shrink: 0;
    min-width: calc(100% / 3 - 10px);

    --icon-size: 60px;
  }

  .detail-name {
    white-space: pre-wrap;
  }

  .detail.is-selected {
    background-color: var(--color-primary);
    color: #fff;
  }
</style>
