<script lang="ts">
  import store from '../../store/kit.store';
  import { _ } from 'svelte-i18n';

  export let isFormDirty: boolean;
  export let key = 'externalOrderId';
  export let required = false;
  export let placeholder = $_('kit.enter-order-number');

  let isDirty = false;

  $: isInvalid = (isDirty || isFormDirty) && required && !$store[key];

  function onInput() {
    isDirty = true;
  }
</script>

<input
  class="form-control"
  class:is-error={isInvalid}
  {placeholder}
  type="text"
  bind:value={$store[key]}
  {required}
  on:input={onInput}
/>

{#if isInvalid}
  <p class="feedback is-invalid">{$_('kit.required-field')}</p>
{/if}
