type TFee = {
  defaultForUnit: number;
  defaultForOrder: number;
  client: number;
  stripe: number;
};

export const fee: TFee = {
  defaultForUnit: 15,
  defaultForOrder: 10,
  client: 5,
  stripe: 1.9,
};

const convertToPercent = (feeObj) => {
  const fee = {} as TFee;
  for (const key in feeObj) {
    fee[key] = feeObj[key] / 100;
  }
  return fee;
};

export const feeInPercent = convertToPercent(fee);
