/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment */

import 'ui/css/global.css';
import type { KitPage } from 'ui/kit';
import '../menu/src/i18n/i18n';

import Tipper from './pages/Tipper.svelte';
import { loadMetaPixel } from 'shared/trackers/meta-pixel';

bootstrap().catch(console.error);

async function bootstrap() {
  const path = window.location.pathname.slice(1);
  const urlParams = new URLSearchParams(window.location.search);

  // old promo materials fallback
  if (path === 'tip/11') {
    return window.location.assign('/tip/vkKqbjxsvTxX59vPyyufkX');
  }

  const [page, response] = await getPageAndResponse(path, {employee: urlParams.get('employee')});

  if (response.unit.settings.googleTagManagerId) {
    GoogleTagManagerInit(response.unit.settings.googleTagManagerId);
  }

  if (response.unit.settings.metaPixel) {
    loadMetaPixel();
    window.fbq('init', response.unit.settings.metaPixel);
    window.fbq('track', 'PageView');
  }
  new Tipper({
    target: document.getElementById('app'),
    props: {
      ...response,
      page,
      kit: response.kit,
    },
  });
}

async function getPageAndResponse(path: string, params) {
  let response: any = {};
  let page: KitPage;

  if (path.match(/^org\/.+/)) {
    response = await fetch(`/api/bootstrap/tip/unit/${path.slice(4)}?employee=${params.employee || ''}`).then((r) => r.json());
    page = 'tip';
  } else if (path.match(/^tip\/.+/)) {
    response = await fetch(`/api/bootstrap/tip/employee/${path.slice(4)}`).then((r) => r.json());
    page = 'tip';
  } else if (path.match(/^order\/.+/)) {
    response = await fetch(`/api/bootstrap/tip/order/${path.slice(6)}`).then((r) => r.json());
    page = 'tip';
  } else if (path.match(/^emps\/.+/)) {
    response = await fetch(`/api/bootstrap/tip/employees/${path.slice(5)}`).then((r) => r.json());
    page = 'tip';
  } else if (path.match(/^review-org\/.+/)) {
    response = await fetch(`/api/bootstrap/review/unit/${path.slice(11)}`).then((r) => r.json());
    page = 'review';
  } else if (path.match(/^review\/.+/)) {
    response = await fetch(`/api/bootstrap/review/employee/${path.slice(7)}`).then((r) => r.json());
    page = 'review';
  } else if (path.match(/^order-review\/.+/)) {
    response = await fetch(`/api/bootstrap/review/order/${path.slice(13)}`).then((r) => r.json());
    page = 'review';
  } else if (path.match(/^ox\/.+/)) {
    const [shortUnitId, token] = path.slice(3).split('/');
    response = await fetch(`/api/bootstrap/tip/pos/${shortUnitId}/${token}`).then((r) => r.json());
    page = 'tip';
  }

  return [page, response];
}
