<script lang="ts">
  import store from '../../store/kit.store';
  import { _ } from 'svelte-i18n';

  import IconfaceAngry from 'ui/icons/faces/IconFaceAngry.svg';
  import IconFaceHappy from 'ui/icons/faces/IconFaceHappy.svg';
  import IconFaceNeutral from 'ui/icons/faces/IconFaceNeutral.svg';

  export let key = 'face';
  export let title = $_('kit.speed-of-service');
  export let labels = {};

  const defaultLabels = {
    angry: $_('kit.service.angry'),
    normal: $_('kit.service.normal'),
    happy: $_('kit.service.happy'),
  };

  function getLabel(key: string): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return labels[key] || defaultLabels[key];
  }

  function select(index: number) {
    $store[key] = index;
  }
</script>

<h2 class="t-h3 text-center mb-2">{title}</h2>

<div class="flex justify-center">
  <button
    type="button"
    class="iconFace iconColorNegative"
    class:selected={$store[key] === 0}
    on:click={() => select(0)}
  >
    <IconfaceAngry />
    {getLabel('angry')}
  </button>

  <button type="button" class="iconFace iconColorNeutral" class:selected={$store[key] === 1} on:click={() => select(1)}>
    <IconFaceNeutral />
    {getLabel('normal')}
  </button>

  <button
    type="button"
    class="iconFace iconColorPositive"
    class:selected={$store[key] === 2}
    on:click={() => select(2)}
  >
    <IconFaceHappy />
    {getLabel('happy')}
  </button>
</div>

<style>
  .iconFace {
    transition: all 0.2s ease-out;
    --icon-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-shrink: 0;
    padding: 10px;
    border: 1px solid var(--color-primary);
    border-radius: 10px;
    color: var(--color-primary);
    margin: 0 5px;
    height: auto;
    width: 90px;
  }

  .iconFace.selected {
    background-color: var(--color-primary);
    color: #fff !important;
  }
</style>
