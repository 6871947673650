<script lang="ts">
  import { isNull, isUndefined, merge, omit, omitBy, reverse } from 'lodash-es';
  import { createEventDispatcher, getContext } from 'svelte';
  import { _ } from 'svelte-i18n';

  import { updateDataLayer, sendEvent } from '../analytics/analytics.service';
  import { sendReview } from '../services/review.service';
  import { calculateTipsFee } from '../services/tips.service';
  import store from '../store/kit.store';

  import type { EmployeeTips, Unit } from 'ui/kit/types';
  import PaymentButtons from 'ui/payment/PaymentButtons.svelte';
  import { analytics } from '../analytics/analytics.store';

  export let amount = 0;
  export let recipients: EmployeeTips[];

  const unit = getContext<Unit>('unit');

  const dispatch = createEventDispatcher();
  let tipsFee: number;
  let minAmount = 2;
  let totalTipAmount: number;

  $: tipsFee = calculateTipsFee($store.amount);
  $: totalTipAmount = $store.isPaidFee ? amount + tipsFee : amount;
  $: purpose = `Tip to ${recipients.map((_) => _.firstName).join(', ')}`;

  $: employees = recipients;
  $: console.log(employees);

  async function beforePayment() {
    if (amount < minAmount) {
      let err = $_('kit.min-amount', { values: { val: `${minAmount} ${unit.organization.currency}` } });
      alert(err);
      throw new Error(err);
    }

    await saveReview();
    sendEvent('tips_pay');
  }

  function onSuccess(e) {
    updateDataLayer(merge($analytics, { event: 'tips_purchase', tips: e.detail }));
    dispatch('success');
  }

  async function saveReview() {
    let normalizedStore = omit($store, ['amount']);
    normalizedStore = omitBy(normalizedStore, isNull);
    normalizedStore = omitBy(normalizedStore, isUndefined);

    let newStore = await sendReview(normalizedStore, unit, null);
    if (newStore?.reviewId) {
      $store.reviewId = newStore.reviewId;
    }
  }
</script>

<div class="pay-buttons">
  <PaymentButtons
    unitId={unit.id}
    currency={unit.organization.currency}
    {purpose}
    amount={totalTipAmount}
    paymentProviders={unit.paymentProviders}
    stripeCardText={$_('kit.leave-a-tip')}
    stripeId={unit.stripeId}
    reviewId={$store.reviewId}
    {beforePayment}
    {employees}
    tipsAmount={totalTipAmount}
    amountPaidFee={$store.isPaidFee ? Math.round(tipsFee * 100) : undefined}
    on:success={onSuccess}
  ></PaymentButtons>
</div>

<style>
  /* .pay-buttons {
    gap: 14px;
  }

  .pay-button {
    flex-grow: 1;
    flex-basis: 0;
  }

  .pay-button:empty {
    display: none;
  }

  .payment-vendor :global(iframe) {
    border-radius: 10px;
    overflow: hidden;
  } */
</style>
