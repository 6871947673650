/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return */

type Class = { new (...args: any[]): any };

export function mountModal<T extends Class>(Modal: T, props: Record<string, any> = {}): InstanceType<T> {
  const root = document.createElement('div');

  document.body.appendChild(root);

  const instance = new Modal({
    target: root,
    props,
  });

  instance.$on('close', () => {
    setTimeout(() => {
      instance.$destroy();
      root.remove();
    }, 400);
  });

  return instance;
}
