<script lang="ts">
  import { getContext, onDestroy, onMount } from 'svelte';
  import { _ } from 'svelte-i18n';

  import type { KitContext } from '../types';

  export let checked: boolean;
  export let fee: number;

  const kitContext = getContext<KitContext>('kit_context');

  onMount(() => {
    checked = true;
  });

  onDestroy(() => {
    checked = false;
  });
</script>

<label class="block select-none">
  <input type="checkbox" class="mr-1" bind:checked />

  <span>{$_('kit.cover-fee', { values: { fee: `${fee.toFixed(2)} ${kitContext.currency}` } })}</span>
</label>
