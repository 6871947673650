<script lang="ts">
  import { getContext, onMount, setContext, SvelteComponent } from 'svelte';
  import '../../menu/src/i18n/i18n';
  import { _ } from 'svelte-i18n';

  import { mountModal } from '../elements/et-modal';
  import { sendEvent } from '../kit/analytics/analytics.service';

  import { analytics } from './analytics/analytics.store';
  import PaidFee from './components/PaidFee.svelte';
  import KitPaymentForm from './components/KitPaymentForm.svelte';
  import SocialLinks from './components/SocialLinks.svelte';
  import ReviewPopup from './components/modals/ReviewPopup.svelte';
  import SuccessPopup from './components/modals/SuccessPopup.svelte';
  import VendorReviewPopup from './components/modals/VendorReviewPopup.svelte';
  import { kitComponents } from './kit';
  import { checkFormValid, checkFormValidity } from './services/form.service';
  import { recalculateKitComponents } from './services/render.service';
  import { calculateTipsFee } from './services/tips.service';
  import store from './store/kit.store';
  import type { Kit, KitContext, Employee, Order, Unit, KitPage, Client } from './types';

  /* Props */
  export let kit: Kit;

  /* Variables */
  const unit = getContext<Unit>('unit');
  const employee = getContext<Employee>('employee');
  const order = getContext<Order>('order');
  const page = getContext<KitPage>('page');
  const client = getContext<Client>('client');
  const renderedComponentList: { [key: string]: SvelteComponent } = {};
  let isFormDirty = false;
  let termsAgree = true;

  /* Main */
  $analytics.unit_id = unit.id;
  $analytics.type = {
    employee: !!employee,
    order: !!$order,
  };

  setContext<KitContext>('kit_context', {
    currency: unit.organization.currency,
  });

  /* Computed */
  $: recalculateKitComponents(kit.components, $store);
  $: tipsFee = calculateTipsFee($store.amount);
  $: isValidForm = checkFormValid(renderedComponentList) && checkFormValidity(kit.components, $store) && termsAgree;
  $: isShowTotal = $order?.amount && !!employee;
  $: if (client) {
    for (const key in client) {
      $store[key] = client[key];
    }
  }

  /* Functions */
  function onSuccess() {
    if ($store.amount && unit.settings.linkAfterPay) {
      window.location.href = unit.settings.linkAfterPay;
      return;
    }

    const tripadvisorComponent = kit.components.find(({ component }) => component === 'TripadvisorReview');
    const redirectInPopup = tripadvisorComponent?.props.redirectInPopup;
    const isTripAdvisorEnabled = $store[tripadvisorComponent?.key || 'tripadvisorReview'];

    const showFormPopup =
      (kit.settings?.collectCustomerData ||
        (page === 'review' && kit.popup?.showAfterFeedback !== false) ||
        (page === 'tip' && kit.popup?.showAfterTips !== false)) &&
      (!$store.phone || !$store.name || !$store.email);

    // redirect without collect user data
    if (isTripAdvisorEnabled && !redirectInPopup) {
      return mountModal(VendorReviewPopup, {
        vendorLink: tripadvisorComponent?.props.link,
        redirectTimeout: tripadvisorComponent?.props.redirectTimeout,
        redirectText: tripadvisorComponent?.props.redirectText,
        hasComment: !!$store.comment,
      });
    }

    if (showFormPopup || isTripAdvisorEnabled) {
      return mountModal(ReviewPopup, {
        unit,
        employee,
        vendorLink: tripadvisorComponent?.props.link,
        redirectIsActive: isTripAdvisorEnabled,
        redirectText: tripadvisorComponent?.props.redirectText,
        popup: kit.popup,
        hasComment: !!$store.comment,
      });
    }

    mountModal(SuccessPopup, {
      popup: kit.popup,
    });
  }

  function touchForm() {
    isFormDirty = true;
  }

  /* Lifecycle */
  onMount(() => {
    sendEvent('view_tips');
  });
</script>

<form class="flex-grow flex flex-col" on:submit|preventDefault novalidate>
  <div class="panel flex-grow flex-shrink-0 flex flex-col">
    <div class="pt-3 mb-auto base-container">
      {#if isShowTotal}
        <div class="t-paragraph c-grey text-center mb-4">
          {$_('kit.total')} <span class="c-black t-h4 ml-2">{$order.amount}</span>
          {unit.organization.currency}
        </div>
      {/if}
      {#each kit.components as component}
        {#if component._isShown}
          <div class="mb-6">
            <svelte:component
              this={kitComponents[component.component]}
              store={$store}
              key={component.key}
              {isFormDirty}
              {...component.props}
              bind:this={renderedComponentList[component.key]}
            />
          </div>
        {/if}
      {/each}

      {#if $store.amount > 0 && unit.settings.clientCanPaidFee}
        <div class="mt-6">
          <PaidFee bind:checked={$store.isPaidFee} fee={tipsFee} />
        </div>
      {/if}
    </div>

    {#if unit.settings.facebook || unit.settings.instagram}
      <div class="pb-3 mt-8">
        <SocialLinks facebook={unit.settings.facebook} instagram={unit.settings.instagram} />
      </div>
    {/if}

    <div class="base-container">
      <label class="inline-flex items-center">
        <input type="checkbox" required bind:checked={termsAgree} />
        <span class="ml-3">
          {$_('kit.terms.first')}
          <a href="https://e-rockets.io/privacy" target="_blank" rel="noreferrer" class="text-blue-500 hover:underline">
            {$_('kit.terms.privacy-policy')}
          </a>
          {$_('kit.terms.and')}
          <a
            href="https://e-rockets.io/termsofuse"
            target="_blank"
            rel="noreferrer"
            class="text-blue-500 hover:underline"
          >
            {$_('kit.terms.terms-use')}
          </a>
        </span>
      </label>
    </div>
  </div>

  <footer class="panel sticky bottom-0">
    <div class="base-container" on:click={touchForm}>
      <KitPaymentForm canLeaveAFeedback={kit?.settings?.canLeaveAFeedback} {isValidForm} on:success={onSuccess} />
    </div>
  </footer>
</form>
