<script lang="ts">
  /* eslint-disable @typescript-eslint/no-unsafe-argument */
  /* eslint-disable @typescript-eslint/no-unsafe-call */

  import { createEventDispatcher, onMount, tick } from 'svelte';
  import { _ } from 'svelte-i18n';

  import EtModal from 'ui/elements/et-modal/et-modal.svelte';
  import store from 'ui/kit/store/kit.store';

  export let vendorLink: string;
  export let redirectTimeout: number;
  export let redirectText: string;
  export let hasComment: boolean;

  let reviewVendorName = '';
  let input: HTMLInputElement;
  let isFallbackShown = false;
  let inputFallback: HTMLTextAreaElement;
  const dispatch = createEventDispatcher();

  if (vendorLink.includes('tripadvisor')) {
    reviewVendorName = 'TripAdvisor';
  } else if (/(g.page)|(google)/.test(vendorLink)) {
    reviewVendorName = 'Google';
  }

  $: redirectLabel = redirectText || $_('kit.redirect-label', { values: { reviewVendorName } });

  function copyReviewToClipboard() {
    if (!navigator.clipboard) {
      void fallbackCopyTextToClipboard();
      return;
    }

    navigator.clipboard.writeText($store.comment).then(redirectWithTimeout, fallbackCopyTextToClipboard);
  }

  async function fallbackCopyTextToClipboard() {
    input.select();

    if (!document.execCommand('copy')) {
      isFallbackShown = true;
      await tick();
      inputFallback.select();
    } else {
      redirectWithTimeout();
    }
  }

  function redirectWithTimeout() {
    if (redirectTimeout === undefined) {
      return;
    }

    setTimeout(goToTripAdVisor, redirectTimeout * 1000);
  }

  function goToTripAdVisor() {
    window.location.href = vendorLink;
  }

  function close() {
    goToTripAdVisor();
    dispatch('close');
  }

  onMount(() => {
    if (hasComment) {
      copyReviewToClipboard();
    }
  });
</script>

<EtModal on:close={close}>
  <input type="text" class="input" value={$store.comment} bind:this={input} />

  {#if hasComment}
    <div class="mb-10 pt-4">
      {#if isFallbackShown}
        <p class="mb-3">{$_('kit.copy-selected', { values: { reviewVendorName } })}</p>
        <textarea class="form-control" rows="4" value={$store.comment} bind:this={inputFallback} />
      {:else}
        <p>{redirectLabel}</p>
      {/if}
    </div>
  {:else}
    <p class="mb-4">{$_('kit.leave-vendor-review', { values: { reviewVendorName } })}</p>
  {/if}

  <button type="button" class="btn is-primary w-full" on:click={goToTripAdVisor}>{$_('kit.letsgo')}</button>
</EtModal>

<style>
  .input {
    position: absolute;
    z-index: -2;
    left: -9999px;
    width: 1px;
    height: 1px;
    pointer-events: none;
  }
</style>
