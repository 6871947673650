<script>
  import { createEventDispatcher } from 'svelte';

  export let value = false;

  const dispatch = createEventDispatcher();

  function toggle() {
    value = !value;
    dispatch('change');
  }
</script>

<div {...$$restProps} class={`flex items-center switchRoot ${$$restProps.class || ''}`} on:click={toggle}>
  <div class="switch mr-2 flex">
    <span class="slider" class:checked={value} />
  </div>
  <span><slot /></span>
</div>

<style>
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: flex;
    width: 36px;
    height: 20px;
  }

  /* The slider */
  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 30px;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    top: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  .switchRoot {
    cursor: pointer;
    padding: 12px 0;
  }
  .switchRoot :global(.slider.checked) {
    background-color: #2196f3;
  }

  .switchRoot :global(.slider.checked) {
    box-shadow: 0 0 1px #2196f3;
  }

  .switchRoot :global(.slider.checked:before) {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
  }
</style>
