<script lang="ts">
  import { createEventDispatcher, onMount, tick } from 'svelte';
  import { _ } from 'svelte-i18n';

  import { defaultFieldKeys, defaultPopupFields, reviewFieldsMap } from './constants';
  import type { ReviewPopupFields } from './types';

  import EtModal from 'ui/elements/et-modal/et-modal.svelte';
  import { patchReview, postReview } from 'ui/kit/services/api.service';
  import store from 'ui/kit/store/kit.store';
  import type { Employee, KitPopup, Unit } from 'ui/kit/types';

  /* Props */
  export let unit: Unit;
  export let employee: Employee;
  export let vendorLink = '';
  export let redirectIsActive: boolean;
  export let redirectText: string;
  export let popup: KitPopup;
  export let hasComment: boolean;

  /* Variables */
  let isLoading = false;
  let isSaved = false;
  let input: HTMLInputElement;
  let isFallbackShown = false;
  let inputFallback: HTMLTextAreaElement;
  let reviewVendorName = '';
  const dispatch = createEventDispatcher();

  const fields: ReviewPopupFields = popup?.fields || defaultPopupFields;
  const formFields = fields.reduce((acc, field) => {
    if (!$store[field.key]) {
      acc.push(field);
    }

    return acc;
  }, [] as ReviewPopupFields);

  const values = {
    name: $store.name || '',
    phone: $store.phone || '',
    email: $store.email || '',
    externalOrderId: $store.externalOrderId || '',
  };

  if (vendorLink.includes('tripadvisor')) {
    reviewVendorName = 'TripAdvisor';
  } else if (/(g.page)|(google)/.test(vendorLink)) {
    reviewVendorName = 'Google';
  }

  /* Computed */
  $: redirectLabel = redirectText || $_('kit.redirect-label', { values: { reviewVendorName } });
  $: subTitle = popup?.subTitle || $_('kit.redirect-sub');
  $: formTitle = popup?.formTitle || $_('kit.redirect-form-title');
  $: dynamicTitle = !$store.rating
    ? $_('kit.thanks-for-feedback')
    : $store.rating < 4
    ? $_('kit.let-us-fix')
    : $_('kit.in-touch');
  $: title = popup?.title || dynamicTitle;
  $: closeable = typeof popup?.closeable === 'boolean' ? popup.closeable : false;

  /* Functions */
  async function onSubmit() {
    isLoading = true;
    try {
      if (!$store.reviewId) {
        await postReview({
          unitId: unit?.id,
          employeeId: employee?.id,
          ...values,
        });
      } else {
        await patchReview({
          id: $store.reviewId,
          ...values,
        });
      }

      isSaved = true;
    } finally {
      isLoading = false;
      if (redirectIsActive) {
        window.location.href = vendorLink;
      }
    }
  }

  function copyReviewToClipboard() {
    if (!navigator.clipboard) {
      void fallbackCopyTextToClipboard();
      return;
    }

    navigator.clipboard.writeText($store.comment).then(() => null, fallbackCopyTextToClipboard);
  }

  async function fallbackCopyTextToClipboard() {
    input?.select();

    if (!document.execCommand('copy')) {
      isFallbackShown = true;
      await tick();
      inputFallback?.select();
    }
  }

  function close() {
    if (redirectIsActive) {
      window.location.href = vendorLink;
    }

    dispatch('close');
  }

  /* Lifecycle */
  onMount(() => {
    if (hasComment) {
      copyReviewToClipboard();
    }
  });
</script>

<EtModal on:close={close} {closeable} data-cy="kit-feedback-popup">
  <form on:submit|preventDefault={onSubmit}>
    {#if isSaved}
      <div class="text-center pt-3 pb-7" data-cy="kit-feedback-popup-success">
        <h2 class="t-h1 mb-2">{title}</h2>
        <p>{subTitle}</p>
      </div>
    {:else}
      <div class="mb-4 text-center">
        <h2 class="t-h1 mb-4">{title}</h2>
        <p>{formTitle}</p>
      </div>

      {#each formFields as field}
        <div class="mb-4">
          <svelte:component
            this={reviewFieldsMap[field.component]}
            bind:value={values[field.key || defaultFieldKeys[field.component]]}
          />
        </div>
      {/each}

      {#if redirectIsActive}
        {#if hasComment}
          <input type="text" class="input" value={$store.comment} bind:this={input} />

          <div class="mb-10 pt-4">
            {#if isFallbackShown}
              <p class="mb-3">{$_('kit.copy-selected', { values: { reviewVendorName } })}</p>
              <textarea class="form-control" rows="4" value={$store.comment} bind:this={inputFallback} />
            {:else}
              <p>{redirectLabel}</p>
            {/if}
          </div>
        {:else}
          <p>{$_('kit.leave-vendor-review', { values: { reviewVendorName } })}</p>
        {/if}
      {/if}

      <button type="submit" class:is-loading={isLoading} class="btn is-primary w-full mt-6">{$_('kit.submit')}</button>
    {/if}
  </form>
</EtModal>

<style>
  .input {
    position: absolute;
    z-index: -2;
    width: 1px;
    height: 1px;
    pointer-events: none;
  }
</style>
