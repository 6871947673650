<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { _ } from 'svelte-i18n';

  import type { Employee } from '../../types';

  export let user: Employee;
  export let selectable = false;
  export let isSelected = false;
  export let amount = 0;

  const dispatch = createEventDispatcher();

  const onSelect = () => dispatch('select');
  const onChangeAmount = (e) => dispatch('change-amount', parseFloat(e.target.value));
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="bg-white rounded-lg p-3 flex items-center border border-gray-100 user mb-3" on:click>
  <div class="w-12 h-12 mr-3 rounded-full bg-gray-50 shrink-0">
    {#if user.avatar}
      <img src={user.avatar?.url} alt="" class="w-full h-full block rounded-full object-cover" />
    {/if}
  </div>

  <div class="min-w-0 overflow-hidden">
    <div>{user.firstName || ''}</div>
    {#if user.position}
      <div class="text-xs text-gray-400 font-semibold tracking-wider">{user.position}</div>
    {/if}
  </div>

  <div class="ml-auto flex items-center">
    {#if selectable}
      {#if isSelected}
        <input
          type="number"
          class="amount-input"
          min="1"
          step="1"
          inputmode="decimal"
          bind:value={amount}
          on:input={onChangeAmount}
          placeholder={$_('kit.amount')}
        />
      {/if}

      <label class="p-2">
        <input type="checkbox" bind:checked={isSelected} on:change={onSelect} />
      </label>
    {/if}
  </div>
</div>

<style>
  .user {
    box-shadow: 0px 0px 40px rgba(58, 74, 89, 0.08);
  }

  .amount-input {
    width: 100px;
    padding: 4px 0;
    font: inherit;
    text-align: center;
    margin: 0;
    border: 0;
    font-size: 1.2em;
    border-bottom: 1px solid var(--color-primary);
    text-indent: 1em;
    box-shadow: none;
  }
</style>
