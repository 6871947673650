<script lang="ts">
  import { _ } from 'svelte-i18n';

  // import store from '../../store/kit.store';

  // export let key: string;
  export let title = $_('kit.multi-choises-title');
  export let choices = [
    $_('kit.multi-choises.0'),
    $_('kit.multi-choises.1'),
    $_('kit.multi-choises.2'),
    $_('kit.multi-choises.3'),
    $_('kit.multi-choises.4'),
  ];

  let multiChoicesSelected = [];

  // $: {
  //   if (!Array.isArray($store[key])) {
  //     $store[key] = [];
  //   }

  //   $store[key] = multiChoicesSelected.reduce((acc, current) => {
  //     acc.push({
  //       question: current,
  //       value: 'yes',
  //       type: 'choice',
  //     });
  //     return acc;
  //   }, []);
  // }
</script>

{#if title}
  <p class="text-center mb-3">{title}</p>
{/if}

<div class="text-center">
  {#each choices as flavour}
    <label class="checkbox">
      <input type="checkbox" bind:group={multiChoicesSelected} name="multiChoices" value={flavour} />
      <span>{flavour}</span>
    </label>
  {/each}
</div>

<style>
  .checkbox {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 5px;
  }

  .checkbox input {
    display: none;
  }

  .checkbox span {
    border-radius: 10px;
    border: 1px solid var(--color-primary);
    display: inline-block;
    padding: 6px 12px;
    transition: all 0.2s;
  }

  .checkbox input:checked + span {
    background-color: var(--color-primary);
    color: #fff;
  }
</style>
