import { feeInPercent } from 'server/src/payments/payment.config';

export function calculateTipsFee(amount?: number): number {
  if (typeof amount !== 'number' && !Number.isNaN(amount)) {
    return 0;
  }

  const fullStripeFee = amount * feeInPercent.stripe + 1;
  const clientFee = amount * feeInPercent.client;

  const totalFee = fullStripeFee + clientFee + (fullStripeFee + clientFee) * feeInPercent.stripe;

  return totalFee;
}
