import Email from './popup-fields/Email/Email.svelte';
import ExternalOrderId from './popup-fields/ExternalOrderId/ExternalOrderId.svelte';
import Name from './popup-fields/Name/Name.svelte';
import Phone from './popup-fields/Phone/Phone.svelte';
import type { ReviewPopupFields } from './types';

export const defaultPopupFields: ReviewPopupFields = [
  {
    component: 'Name',
    key: 'name',
  },
  {
    component: 'Phone',
    key: 'phone',
  },
  {
    component: 'Email',
    key: 'email',
  },
];

export const reviewFieldsMap = {
  Name,
  Phone,
  Email,
  ExternalOrderId,
};

export const defaultFieldKeys = {
  Name: 'name',
  Phone: 'phone',
  Email: 'email',
  ExternalOrderId: 'externalOrderId',
};
