<script lang="ts">
  import { quintOut } from 'svelte/easing';
  import { slide } from 'svelte/transition';

  import store from '../../store/kit.store';

  import type { ChoicesItem } from './types';

  import EtRating from 'ui/elements/et-rating/et-rating.svelte';
  import IconCheck from 'ui/icons/IconCheck.svg';

  export let key = 'choices';

  export let items: ChoicesItem[] = [
    {
      question: 'Is this your first time ordering from this restaurant?',
      choices: ['Yes ✅ ', 'No ❌'],
    },
    {
      question: 'Was the food delivered…',
      choices: ['earlier than', 'same time as', 'later than'],
      footer: 'specified on the App',
    },
    {
      question: 'Was the food delivered…',
      choices: ['Cold 🧊', 'Warm ♨️', 'Hot 🔥'],
    },
    {
      question: 'How likely are you to recommend us to a friend?',
      stars: true,
    },
    {
      question: 'Please rate the packaging',
      stars: true,
    },
    {
      question: 'How would you rate your overall experience?',
      stars: true,
    },
  ];

  let viewIndex = 0;
  let isComplete = false;

  function onChange(type: any, val) {
    if (!Array.isArray($store[key])) {
      $store[key] = [];
    }

    $store[key] = ($store[key] as any[]).concat({
      question: items[viewIndex].question,
      value: val,
      type,
    });

    if (items[viewIndex + 1]) {
      viewIndex++;
    } else {
      isComplete = true;
    }
  }
</script>

<div class="text-center">
  {#if !isComplete}
    {#key viewIndex}
      <div transition:slide={{ duration: 300, easing: quintOut }}>
        <h2 class="t-h3 text-center mb-2">{items[viewIndex].question}</h2>

        {#if items[viewIndex].choices}
          <div class="choices">
            {#each items[viewIndex].choices as choice}
              <label class="control">
                <input
                  on:change={(e) => onChange('choice', e.target.value)}
                  type="radio"
                  name={viewIndex.toString()}
                  value={choice}
                  class="systemControl"
                />

                <span class="focusable">{choice}</span>
              </label>
            {/each}
          </div>
        {:else if items[viewIndex].stars}
          <div class="stars">
            <EtRating value={null} on:change={(e) => onChange('star', e.detail)} />
          </div>
        {/if}

        {#if items[viewIndex].footer}
          <div class="mt-2">{items[viewIndex].footer}</div>
        {/if}
      </div>
    {/key}
  {/if}

  {#if isComplete}
    <div class="success" transition:slide={{ duration: 300, easing: quintOut }} style="font-size: 40px">
      <IconCheck />
    </div>
  {/if}
</div>

<style>
  .control {
    display: inline-block;
    cursor: pointer;
    margin: 4px;
  }

  .focusable {
    display: inline-block;
    padding: 6px 12px;
    border-radius: 10px;
    border: 1px solid var(--color-primary);
  }

  .systemControl {
    position: absolute;
    opacity: 0;
  }

  .systemControl:focus-visible + .focusable {
    outline: auto 2px SelectedItem;
  }

  .systemControl:active + .focusable {
    background-color: var(--color-primary);
    color: #fff;
  }
</style>
