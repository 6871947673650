<script lang="ts">
  import merge from 'lodash/merge';
  import { createEventDispatcher, getContext } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { PaymentProviderType } from 'shared/payment-methods';

  import { updateDataLayer, sendEvent } from '../analytics/analytics.service';
  import { analytics } from '../analytics/analytics.store';
  import { sendReview } from '../services/review.service';
  import { calculateTipsFee } from '../services/tips.service';
  import store from '../store/kit.store';
  import { isEmptyStore } from '../store/store.service';

  import IconComment from 'ui/icons/IconComment.svg';
  import type { Employee, Order, Unit } from 'ui/kit/types';
  import PaymentButtons from 'ui/payment/PaymentButtons.svelte';

  export let isValidForm: boolean;
  export let canLeaveAFeedback = true;

  const unit = getContext<Unit>('unit');
  const order = getContext<Order>('order');
  const employee = getContext<Employee>('employee');

  const dispatch = createEventDispatcher();
  let tipsFee: number;
  let totalTipAmount: number;

  $: tipsFee = calculateTipsFee($store.amount);
  $: totalTipAmount = $store.isPaidFee ? $store.amount + tipsFee : $store.amount;
  $: isReviewOnly = !$store.amount;
  $: canMakePayment = totalTipAmount && totalTipAmount >= 2;
  $: isEmptyFeedback = isEmptyStore($store);
  $: showOnlyReviewButton = isReviewOnly && canLeaveAFeedback;
  $: employees = employee?.id ? [{ id: employee.id, amount: totalTipAmount }] : undefined;

  async function beforePayment() {
    let newStore = await sendReview($store, unit, employee, $order?.id);
    if (newStore?.reviewId) {
      $store.reviewId = newStore.reviewId;
    }
    sendEvent('tips_pay');
  }

  function onSuccess(e) {
    updateDataLayer(merge($analytics, { event: 'tips_purchase', tips: e.detail }));
    dispatch('success');
  }

  let isReviewSaving = false;
  async function leaveFeedback() {
    isReviewSaving = true;
    await beforePayment().finally(() => {
      dispatch('success');
      isReviewSaving = false;
      sendEvent('tips_review');
    });
  }
</script>

{#if showOnlyReviewButton}
  <button
    type="button"
    class="btn is-primary w-full feedback-button"
    on:click={leaveFeedback}
    class:is-loading={isReviewSaving}
    disabled={!isValidForm || isEmptyFeedback}
    data-cy="kit-feedback-button"
  >
    <IconComment class="mr-2" />
    {$_('kit.leave-feedback')}
  </button>
{/if}

<div class="pay-buttons" class:hidden={showOnlyReviewButton}>
  <PaymentButtons
    unitId={unit.id}
    purpose={`Tip for ${employee?.firstName || unit.name}`}
    currency={unit.organization.currency}
    paymentProviders={unit.paymentProviders}
    isAvailable={!canMakePayment || !isValidForm}
    stripeCardText={$_('kit.leave-a-tip')}
    stripeId={unit.paymentProviders.find((p) => p.type === PaymentProviderType.STRIPE)?.primaryId}
    reviewId={$store.reviewId}
    orderIds={$store.orderId ? [$order.id] : undefined}
    {beforePayment}
    {employees}
    tipsAmount={totalTipAmount}
    amountPaidFee={$store.isPaidFee ? Math.round(tipsFee * 100) : undefined}
    on:success={onSuccess}
  ></PaymentButtons>
</div>

<style>
  /* .pay-buttons {
    gap: 14px;
  }

  .pay-buttons.disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  .pay-button {
    flex-grow: 1;
    flex-basis: 0;
  }

  .pay-button:empty {
    display: none;
  }
 */

  .feedback-button[disabled] {
    pointer-events: none;
  }
</style>
