<script lang="ts">
  /* eslint-disable @typescript-eslint/no-unsafe-call */
  import { _ } from 'svelte-i18n';

  import { onDestroy } from 'svelte';

  import store from '../../store/kit.store';

  export let isFormDirty: boolean;
  export let key = 'phone';
  export let required = false;
  export let title = ''; // We’d love to stay in touch
  export let placeholder = $_('kit.enter-phone');

  let isDirty = false;

  $: isInvalid = (isDirty || isFormDirty) && required && !$store[key];

  function phoneFix(e) {
    const target = e.target as HTMLInputElement;
    const phone = target.value.replace(/[^0-9+]/g, '');
    target.value = phone;
    $store[key] = phone.trim();
  }

  function onInput(e) {
    isDirty = true;
    phoneFix(e);
  }

  onDestroy(() => {
    $store[key] = null;
  });
</script>

{#if title}
  <p class="text-center mb-3">{title}</p>
{/if}

<input
  class="form-control"
  class:is-error={isInvalid}
  type="tel"
  name={key}
  value={$store[key] || ''}
  on:input={onInput}
  on:blur={phoneFix}
  {placeholder}
  {required}
/>

{#if isInvalid}
  <p class="feedback is-invalid">{$_('kit.required-field')}</p>
{/if}
