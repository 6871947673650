<script lang="ts">
  import { _ } from 'svelte-i18n';

  /* eslint-disable @typescript-eslint/no-unsafe-call */
  export let value: string;
  export let required = false;
  export let placeholder = $_('kit.phone-number');

  let isDirty = false;

  $: isInvalid = isDirty && required && !value;

  function phoneFix(e) {
    const target = e.target as HTMLInputElement;
    const phone = target.value.replace(/[^0-9+]/g, '');
    target.value = phone;
    value = phone.trim();
  }

  function onInput(e) {
    isDirty = true;
    phoneFix(e);
  }
</script>

<div class="sr-only">{placeholder}</div>
<input
  class="form-control"
  class:is-error={isInvalid}
  type="tel"
  name="phone"
  value={value || ''}
  on:input={onInput}
  on:blur={phoneFix}
  {placeholder}
  {required}
/>

{#if isInvalid}
  <p class="feedback is-invalid">{$_('kit.required-field')}</p>
{/if}
