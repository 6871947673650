<script lang="ts">
  import store from '../../store/kit.store';
  import { _ } from 'svelte-i18n';

  import type { ReviewImage } from './types';

  import IconCamera from 'ui/icons/IconCamera.svg';

  export let key = 'images';
  export let maxFiles = 3;

  let errors = {};

  const maxFileSize = 10 * 1024 * 1024;

  const validFileType = (file: File) => /^image\//.test(file.type);
  const validFileSize = (file: File) => file.size < maxFileSize;
  const resetErrors = () => {
    for (const error in errors) {
      errors[error] = '';
    }
  };

  const onChange = (e: InputEvent) => {
    if (!$store[key]) {
      $store[key] = [];
    }

    const target = e.target as HTMLInputElement;
    resetErrors();
    if (target.files && target.files.length) {
      for (const file of target.files) {
        if ($store[key].length < maxFiles) {
          if (!validFileSize(file)) {
            errors['maxFileSize'] = 'Maximum file size 10 MB';
          } else if (!validFileType(file)) {
            errors['fileTypes'] = 'Images only';
          } else {
            Object.defineProperty(file, 'id', {
              writable: true,
              value: `${+new Date()}`
                .split('')
                .sort(() => 0.5 - Math.random())
                .join(''),
            });

            $store[key] = [...$store[key], { file }];
          }
        } else {
          errors['fileLimit'] = `Maximum ${maxFiles} images`;
        }
      }
      target.value = '';
    }
  };

  const showPreview = (node: HTMLImageElement, file: File) => {
    const getSrc = (file: File) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = (e) => {
        node.src = e.target.result.toString();
      };

      reader.onloadend = () => {
        const parentNode = node.closest('.image-wrap');
        parentNode.removeAttribute('hidden');
      };
    };

    getSrc(file);

    return {
      update: (newFile: File) => {
        getSrc(newFile);
      },
    };
  };

  const remove = (image) => {
    $store[key] = ($store[key] as ReviewImage[]).filter((imageInStore) => image !== imageInStore);
    resetErrors();
  };
</script>

{#if $store[key]?.length}
  <div class="overflow-x-auto mb-2">
    <div class="images">
      {#each $store[key] as image (image.file)}
        <div class="image-wrap" hidden>
          <button class="image-remove" on:click|preventDefault={() => remove(image)} />
          <img src="" class="image" alt="preview" use:showPreview={image.file} />
        </div>
      {/each}
    </div>
  </div>
{/if}

{#if Object.keys(errors).length}
  <div class="c-red text-center mt-3 mb-3">
    {#each Object.values(errors) as error}
      <span>{error}</span>
    {/each}
  </div>
{/if}

{#if !$store[key] || $store[key]?.length < maxFiles}
  <div class="flex justify-center">
    <input type="file" multiple id="images" class="inputfile" on:input={onChange} accept="image/*" />
    <label for="images" class="btn is-pseudo">
      <IconCamera class="c-secondary mr-2" />
      <div class="btn-text">{$_('kit.add-photos')}</div>
    </label>
  </div>
{/if}

<style scoped>
  .images {
    text-align: center;
    padding: 10px 0;
    white-space: nowrap;
  }

  .image-wrap {
    position: relative;
    display: inline-block;
    border-radius: 5px;
    box-shadow: 4px 5px 8px 0 rgba(34, 60, 80, 0.2);
  }

  .image-wrap:not(:last-child) {
    margin-inline-end: 10px;
  }

  .image-remove {
    position: absolute;
    width: 22px;
    height: 22px;
    border: none;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.8);
    right: -10px;
    top: -10px;
  }

  .image-remove::before,
  .image-remove::after {
    position: absolute;
    left: 10px;
    top: 5px;
    content: ' ';
    height: 12px;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .image-remove::before {
    transform: rotate(45deg);
  }

  .image-remove::after {
    transform: rotate(-45deg);
  }

  .image {
    display: block;
    max-width: 100%;
    max-height: 100px;
    object-fit: cover;
    border-radius: inherit;
  }

  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
</style>
