<script lang="ts">
  import { sendEvent } from '../../analytics/analytics.service';
  import store from '../../store/kit.store';
  import { _ } from 'svelte-i18n';

  export let key = 'comment';
  export let placeholder = $_('kit.comment');
  export let rows = 3;
  export let minLength: number = null;

  $: isValid = true;

  function onBlur() {
    if ($store[key]) {
      sendEvent('tips_response');
    }
  }
  export function getIsValid() {
    if (minLength) {
      if ($store[key]?.length >= minLength) {
        isValid = true;
        return true;
      } else {
        isValid = false;
        return false;
      }
    }

    isValid = true;
    return true;
  }
</script>

<textarea
  name={key}
  class="form-control"
  class:is-error={!isValid}
  {rows}
  {placeholder}
  on:blur={onBlur}
  bind:value={$store[key]}
/>

{#if !isValid}
  <p class="feedback is-invalid">{$_('kit.min-comment', { values: { val: minLength || 1 } })}</p>
{/if}
