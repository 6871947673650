<script lang="ts">
  import store from '../../store/kit.store';
  import { _ } from 'svelte-i18n';

  import IconFaceAngry from 'ui/icons/faces/IconFaceAngry.svg';
  import IconFaceHappy from 'ui/icons/faces/IconFaceHappy.svg';
  import IconFaceLike from 'ui/icons/faces/IconFaceLike.svg';
  import IconFaceNeutral from 'ui/icons/faces/IconFaceNeutral.svg';
  import IconFaceUnsatisfied from 'ui/icons/faces/IconFaceUnsatisfied.svg';

  export let key = 'rating';
  export let title = $_('kit.face');

  function select(index: number) {
    $store[key] = index;
  }
</script>

<h2 class="t-h3 text-center mb-2">{title}</h2>

<div class="flex justify-center">
  <button
    type="button"
    class="iconFace iconColorNegative"
    class:selected={$store[key] === 1}
    on:click={() => select(1)}
  >
    <IconFaceAngry />
  </button>

  <button
    type="button"
    class="iconFace iconColorNegative"
    class:selected={$store[key] === 2}
    on:click={() => select(2)}
  >
    <IconFaceUnsatisfied />
  </button>

  <button
    type="button"
    class="iconFace iconColorNeutral"
    class:selected={$store[key] === 3}
    on:click|trusted={() => select(3)}
  >
    <IconFaceNeutral />
  </button>

  <button
    type="button"
    class="iconFace iconColorPositive"
    class:selected={$store[key] === 4}
    on:click={() => select(4)}
  >
    <IconFaceLike />
  </button>

  <button
    type="button"
    class="iconFace iconColorPositive"
    class:selected={$store[key] === 5}
    on:click={() => select(5)}
  >
    <IconFaceHappy />
  </button>
</div>

<style>
  .iconFace {
    width: 50px;
    height: 50px;
    margin: 0 2px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    transition: all 0.2s ease-out;

    --icon-size: 30px;
  }

  .iconColorNegative {
    color: var(--color-secondary);
  }
  .iconColorNeutral {
    color: var(--color-neutral);
  }
  .iconColorPositive {
    color: var(--color-primary);
  }

  .iconFace.selected {
    background-color: var(--color-primary);
    color: #fff;
  }
</style>
