<script lang="ts">
  import EtModal from 'ui/elements/et-modal/et-modal.svelte';
  import type { KitPopup } from 'ui/kit/types';
  import { _ } from 'svelte-i18n';

  export let popup: KitPopup;

  $: title = popup?.title || $_('kit.thanks-for-feedback');
  $: subTitle = popup?.subTitle || $_('kit.redirect-sub');
</script>

<EtModal on:close>
  <div class="text-center pt-5 pb-7">
    <h1 class="t-h1 text-center mb-2">{title}</h1>
    <p>{subTitle}</p>
  </div>
</EtModal>
