<script lang="ts">
  import type { KitPageDto } from 'shared/dto/kit-page-response.dto';
  import { onDestroy, onMount, setContext } from 'svelte';
  import { writable } from 'svelte/store';
  import type { Kit } from 'ui/kit';
  import KitComponent from 'ui/kit/Kit.svelte';
  import KitEmployees from 'ui/kit/KitEmployees.svelte';
  import Header from 'ui/kit/components/Header.svelte';
  import type { Employee, KitPage, Order, Unit, Client } from 'ui/kit/types';

  import PoweredBy from '../components/PoweredBy.svelte';
  import Suspended from '../components/Suspended.svelte';

  export let kit: Kit;
  export let unit: Unit;
  export let employee: Employee;
  export let employees: Employee[];
  export let order: KitPageDto['order'];
  export let page: KitPage;
  export let client: Client;

  setContext<Unit>('unit', unit);
  setContext<Employee>('employee', employee);
  setContext<Employee[]>('employees', employees);
  setContext<Order>('order', writable(order));
  setContext<KitPage>('page', page);
  setContext<Client>('client', client);

  onMount(() => {
    document.body.setAttribute('style', kit?.style);
  });

  onDestroy(() => {
    document.body.removeAttribute('style');
  });
</script>

<svelte:head>
  <title>{unit.name || ''} | e-rockets</title>
</svelte:head>

<Header />
<PoweredBy />

<main class="flex-shrink-0 flex flex-col flex-grow">
  {#if unit.organization.suspended}
    <Suspended />
  {:else if employees}
    <KitEmployees {kit} />
  {:else}
    <KitComponent {kit} />
  {/if}
</main>
