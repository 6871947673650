import type { SvelteComponent } from 'svelte';

import { defaultKitKeys } from '../kit';
import type { KitStore } from '../store/kit.store';
import type { KitComponents } from '../types';

export function checkFormValidity(components: KitComponents, store: KitStore): boolean {
  for (const component of components) {
    const key = component.key || defaultKitKeys.get(component.component);

    if (key && component.props?.required) {
      if (component._isShown && !validateField(store[key])) {
        return false;
      }
    }
  }

  return true;
}

function validateField(value: any): boolean {
  if (value === null || typeof value === 'undefined' || Number.isNaN(value)) {
    return false;
  }

  if (typeof value === 'string') {
    return value.trim().length > 0;
  }

  if (Array.isArray(value)) {
    return value.length > 0;
  }

  return true;
}

type checkFormValidArgComponents = {
  [key: string]: SvelteComponent & { getIsValid?: () => boolean };
};

export function checkFormValid(components: checkFormValidArgComponents): boolean {
  for (const component of Object.values(components).filter(Boolean)) {
    if (component.getIsValid) {
      return component.getIsValid();
    }
  }

  return true;
}
