import { UploadState, type ReviewImage } from '../elements/UploadImage/types';
import type { KitStore } from '../store/kit.store';
import { getCleanKitStore } from '../store/store.service';

import { patchReview, postReview, uploadReviewImages } from './api.service';

import { HttpStatus } from 'ui/http';
import type { Employee, Unit } from 'ui/kit/types';

export async function sendReview(store: KitStore, unit: Unit, employee: Employee, externalOrderId?: string) {
  const cleanStore = getCleanKitStore(store);
  const { reviewId, images, ...data } = cleanStore;

  if (!Object.keys(cleanStore).length) {
    return;
  }

  const saveReview = reviewId
    ? patchReview({
        id: reviewId,
        ...data,
      })
    : postReview({
        employeeId: employee?.id,
        unitId: unit.id,
        externalOrderId,
        ...data,
      });

  await saveReview
    .then((res) => res.json())
    .then(({ id }: { id: string }) => {
      // if POST
      if (id) {
        store.reviewId = id;
      }
    });

  void uploadImages(store.reviewId, images);

  return store;
}

async function uploadImages(reviewId: string, images: ReviewImage[]) {
  const notUploadedImages = images?.filter((image) => !image.state);

  if (notUploadedImages?.length) {
    const fd = new FormData();
    for (const image of notUploadedImages) {
      fd.append(image.file.name, image.file);
      image.state = UploadState.Uploading;
    }

    const resp = await uploadReviewImages(reviewId, fd);
    if (resp.status === HttpStatus.PAYLOAD_TOO_LARGE) {
      alert(`File size exceeds the maximum limit: 10 MB`);
      for (const image of notUploadedImages) {
        image.state = null;
      }
    }
    if (resp.status <= 201) {
      for (const image of notUploadedImages) {
        image.state = UploadState.Uploaded;
      }
    }
  }
}
