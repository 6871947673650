<script lang="ts">
  import { tick } from 'svelte';
  import { _ } from 'svelte-i18n';

  import { sendEvent } from '../../analytics/analytics.service';
  import { kitComponents } from '../../kit';
  import type { KitComponents } from '../../types';

  import IconCoin from 'ui/icons/IconCoin.svg';

  export let components: KitComponents;
  export let label = $_('kit.leave-a-tip');

  let root: HTMLDivElement;
  let isShown = false;

  async function show() {
    isShown = true;
    sendEvent('tips_show_pay_form');
    await tick();
    let amountElement = root.querySelector<HTMLInputElement>('input[name="amount"]');
    amountElement?.focus();
  }
</script>

<div bind:this={root}>
  {#if isShown}
    {#each components as component}
      <div class="mb-6">
        <svelte:component this={kitComponents[component.component]} {...component.props} key={component.key} />
      </div>
    {/each}
  {:else}
    <button type="button" class="btn is-ghost w-full" on:click={show}>
      <IconCoin class="c-secondary mr-2" />
      <div class="btn-text">{label}</div>
    </button>
  {/if}
</div>
