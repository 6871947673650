import type { BaseKitComponent, KitComponents } from '../types';

export function recalculateKitComponents(components: KitComponents, context: Record<string, any>) {
  for (const component of components) {
    component._isShown = shouldComponentRender(component, context);
  }
}

function shouldComponentRender(component: BaseKitComponent, context: Record<string, any>) {
  if (!component.when) {
    return true;
  }

  try {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    const whenOperation = new Function(`with (arguments[0]) { return ${component.when} }`);
    return !!whenOperation(context);
  } catch (error) {
    if (error instanceof ReferenceError) {
      console.error('Some key in "When" is not defined. See settings TIP/Review in admin panel', error);
    }

    // console.error('"When" condition is invalid when rendering "KIT"', { when: component.when, error });
    return;
  }
}
