<script lang="ts">
  import { roundPrice } from 'shared/lib/round-price';
  import { getContext, onMount, setContext } from 'svelte';
  import { _ } from 'svelte-i18n';

  import { sendEvent } from '../kit/analytics/analytics.service';

  import { analytics } from './analytics/analytics.store';
  import KitPaymentFormEmployees from './components/KitPaymentFormEmployees.svelte';
  import SelectMultiple from './components/employees/SelectMultiple.svelte';
  import SelectSingle from './components/employees/SelectSingle.svelte';
  import SuccessPopup from './components/modals/SuccessPopup.svelte';
  import store, { resetKit, initData } from './store/kit.store';
  import type { KitContext, Unit, Client, Employee, EmployeeTips, Kit } from './types';

  import { mountModal } from 'ui/elements/et-modal';
  import EtSwitch from 'ui/elements/et-switch/et-switch.svelte';
  import IconCaret from 'ui/icons/IconCaret.svg';

  export let kit: Kit;

  /* Variables */
  const unit = getContext<Unit>('unit');
  const client = getContext<Client>('client');

  const employees = getContext<Employee[]>('employees');
  let multiple = false;
  let selectedEmployees: EmployeeTips[] = [];

  $: usersWithTips = calculateUsersWithTips(multiple, selectedEmployees, $store);
  $: totalAmount = roundPrice(usersWithTips.reduce((acc, item) => acc + (item.tips || 0), 0));

  /* Main */
  $analytics.unit_id = unit.id;

  setContext<KitContext>('kit_context', {
    currency: unit.organization.currency,
  });

  /* Lifecycle */
  onMount(() => {
    sendEvent('view_tips_multiple');
    initStore();
  });

  function reset() {
    multiple = false;
    selectedEmployees = [];
    initStore();
  }

  function initStore() {
    resetKit();
    if (client) {
      for (const key in client) {
        $store[key] = client[key];
      }
    }
  }

  function onMultipleChange() {
    initStore();
    selectedEmployees = [];

    if (!multiple) {
      $store.amount = null;
    }
  }

  function onSuccess() {
    mountModal(SuccessPopup, {
      title: 'Thank you',
      subTitle: '',
    });
  }

  function calculateUsersWithTips(): EmployeeTips[] {
    let arr: EmployeeTips[] = [];
    if (!multiple && selectedEmployees[0]) {
      arr = [{ ...selectedEmployees[0], tips: $store.amount }];
    } else {
      arr = selectedEmployees;
    }

    return arr.filter((i) => i.tips > 0);
  }
</script>

<form class="flex-grow flex flex-col" on:submit|preventDefault novalidate>
  <div class="panel flex-grow flex-shrink-0 flex flex-col">
    <div class="pt-3 mb-auto base-container">
      <div class="flex items-center mb-5">
        {#if selectedEmployees.length}
          <button type="button" class="flex mr-4 p-2 text-lg text-gray-600" on:click={reset}>
            <IconCaret class="-rotate-90" />
          </button>
        {/if}

        <EtSwitch bind:value={multiple} on:change={onMultipleChange}>{$_('tips.select-multiple-specialists')}</EtSwitch>
      </div>

      {#if usersWithTips.length}
        <div class="t-paragraph c-grey text-center mb-4">
          {$_('cart.total')}<span class="c-black t-h4 ml-2">{totalAmount}</span>
          {unit.organization.currency}
        </div>
      {/if}

      {#if multiple}
        <SelectMultiple {kit} {employees} bind:selectedEmployees />
      {:else}
        <SelectSingle {kit} {employees} bind:selectedEmployees />
      {/if}
    </div>
  </div>

  {#if ((!multiple && selectedEmployees[0]) || multiple) && totalAmount > 0}
    <footer class="panel sticky bottom-0">
      <div class="base-container">
        <KitPaymentFormEmployees amount={totalAmount} recipients={usersWithTips} on:success={onSuccess} />
      </div>
    </footer>
  {/if}
</form>
