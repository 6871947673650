<script lang="ts">
  import IconFacebook from 'ui/icons/socials/IconFacebook.svg';
  import IconInstagram from 'ui/icons/socials/IconInstagram.svg';
  import { _ } from 'svelte-i18n';

  export let facebook = null;
  export let instagram = null;
  export let title = $_('kit.socials-title');
</script>

<p class="text-center t-caption mb-2">{title}</p>

<div class="flex items-center justify-center">
  {#if facebook}
    <a href={facebook} class="link" target="_blank" rel="noopener noreferrer">
      <IconFacebook />
    </a>
  {/if}

  {#if instagram}
    <a href={instagram} class="link" target="_blank" rel="noopener noreferrer">
      <IconInstagram />
    </a>
  {/if}
</div>

<style>
  .link {
    margin: 0 9px;
    border-radius: 50%;
    background-color: var(--social-btns-color);
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--base-bg);
    flex-shrink: 0;

    --icon-size: 20px;
  }

  .link:hover {
    background-color: var(--color-primary);
  }
</style>
