export const DETAIL_DEFAULTS = {
  atmosphere: {
    name: 'Atmosphere',
    icon: 'chat',
  },

  food: {
    name: 'Food',
    icon: 'food',
  },

  service: {
    name: 'Service',
    icon: 'service',
  },

  hookah: {
    name: 'Shisha',
    icon: 'hookah',
  },

  team: {
    name: 'Team',
    icon: 'team',
  },

  delivery: {
    name: 'Delivery',
    icon: 'fast-courier',
  },

  packaging: {
    name: 'Packaging',
    icon: 'food-bag',
  },

  hot_food: {
    name: 'Food',
    icon: 'hot-serve',
  },
};
