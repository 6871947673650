import type { KitStore } from '../../store/kit.store';
import type { BaseKitComponent } from '../../types';

export type UploadImage = BaseKitComponent & {
  component: 'UploadImage';
  key?: keyof KitStore;
  props?: {
    maxFiles?: number;
  };
};

export enum UploadState {
  Uploading,
  Uploaded,
}

export type ReviewImage = {
  file: File;
  state?: UploadState;
};
