<script lang="ts">
  import { onMount, SvelteComponent } from 'svelte';

  import User from './User.svelte';

  import { kitComponents } from 'ui/kit/kit';
  import { recalculateKitComponents } from 'ui/kit/services/render.service';
  import store from 'ui/kit/store/kit.store';
  import type { Employee, EmployeeTips, Kit } from 'ui/kit/types';

  export let employees: Employee[];
  export let selectedEmployees: EmployeeTips[];
  export let kit: Kit;

  const renderedComponentList: { [key: string]: SvelteComponent } = {};

  $: recalculateKitComponents(kit.components, $store);
  $: selectMultiple($store.amount);

  function selectUser(user: Employee) {
    if (selectedEmployees.find((u) => u.id === user.id)) {
      selectedEmployees = selectedEmployees.filter((i) => i.id !== user.id);
    } else {
      selectedEmployees = [...selectedEmployees, { ...user, tips: $store.amount }];
    }

    employees = [...employees];
  }

  function getSelected(user: Employee) {
    return selectedEmployees.find((u) => u.id === user.id);
  }

  function selectMultiple() {
    selectedEmployees.forEach((user) => {
      user.tips = $store.amount;
    });
    selectedEmployees = [...selectedEmployees];
    employees = [...employees];
  }

  function onChangeAmount(user: Employee, amount: number) {
    const employee = selectedEmployees.find((u) => u.id === user.id);
    if (employee) {
      employee.tips = amount;
    }
    selectedEmployees = [...selectedEmployees];
  }

  onMount(() => {
    $store.amount = 15;
  });
</script>

{#if selectedEmployees.length}
  {#each kit.components as component}
    {#if component._isShown}
      <div class="mb-6">
        <svelte:component
          this={kitComponents[component.component]}
          store={$store}
          key={component.key}
          {...component.props}
          bind:this={renderedComponentList[component.key]}
        />
      </div>
    {/if}
  {/each}
{/if}

{#each employees as user}
  <User
    {user}
    selectable
    isSelected={!!getSelected(user)}
    amount={getSelected(user)?.tips}
    on:select={() => selectUser(user)}
    on:change-amount={(e) => onChangeAmount(user, e.detail)}
  />
{/each}
